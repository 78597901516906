import React, { useState, useRef, useEffect } from 'react'
import { Box, Paper, Stack, LinearProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars-2'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
// import FilterAltIcon from '@mui/icons-material/FilterAlt'
import ErrorDialog from '../ErrorDialog/ErrorDialog'
import mainTheme from '../../theme/MainTheme'
import { MODE } from '../../config/constants'
import { rotateAngle } from '../../shared/sortFunctions'
import { getAll } from '../../api/logs'
import { time } from '../../shared/time'

const RowBox = ({ children }) => <Box sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', mr: 2 }}>{children}</Box>

const CutListRow = ({ element, openFunc }) => {
  return (
    <Paper onClick={() => openFunc(element._id)}>
      <Stack direction='row' sx={{p: 1, mt: 1, cursor: 'pointer' }}>
        <Box width='25%'><RowBox>{element.name}</RowBox></Box>
        <Box width='25%'><RowBox>{element?.promoter?.name}</RowBox></Box>
        <Box width='13%'><RowBox>{time(element.startDate)}</RowBox></Box>
        <Box width='13%'><RowBox>{time(element.endDate)}</RowBox></Box>
        <Box width='8%'><RowBox>{element.totalStreams || '-'}</RowBox></Box>
        <Box width='8%'><RowBox>{element.totalDevices}</RowBox></Box>
        <Box width='8%'><RowBox>{element.totalLogs}</RowBox></Box>
      </Stack>
    </Paper>
  )
}


const EventList = () => {
  // const { user, setUser } = useContext(UserContext)
  const [events, setEvents] = useState(null)
  const [query, setQuery] = useState({ pageNumber: 1, pageSize: 20, sortBy: { endDate: -1 }, noPagination: false,  hasNextPage: false })
  const [showError, setShowError] = useState({ show: false, message: '' })
  const firstRenderSharedRef = useRef(true)

  const history = useNavigate()
  const openSingleCutList = (_id) => history(`/event/${_id}`)
  
  const handlePagination = (scrollTop, height, scrollHeight) => {
    if (scrollTop + height < scrollHeight - 20) return
    if (query.hasNextPage) setQuery({ ...query, pageNumber: query.pageNumber + 1, hasNextPage: false })
  }

  const renderSort = (field) => {
    const newQuery = { ...query, pageNumber: 1, hasNextPage: false }
    if (!(field in query.sortBy)) {
      newQuery.sortBy =  { [field]: -1 }
    } else {
      query.sortBy[field] === 1
      ? newQuery.sortBy = { [field]: -1 }
      : newQuery.sortBy = { [field]: 1 }
    }
    setQuery(newQuery)
  }

  const sortArrow = (field) => {
    if (!(field in query.sortBy)) return null
    return query.sortBy[field] === 1 ? <KeyboardArrowUpIcon color='primary' sx={rotateAngle(true)}/> : <KeyboardArrowDownIcon color='primary' sx={rotateAngle(false)}/>
  }

  useEffect(() => {
    if (firstRenderSharedRef.current && MODE === 'development') {
      firstRenderSharedRef.current = false
      return
    }

    const body = { pageNumber: query.pageNumber, pageSize: 20, sortBy: { ...query.sortBy, createdAt: -1 } }

    getAll(body)
      .then(res => {
        setEvents(events => query.pageNumber === 1 ? res.docs : [ ...events, ...res.docs ])
        setQuery(query => ({ ...query, pageNumber: res.page, hasNextPage: res.hasNextPage }))
      })
      .catch(e => setShowError({ show: true, message: e.message }))
  }, [query.pageNumber, query.sortBy])

  return (
    <Paper sx={{p: 2, m: 2, fontSize: '14px' }}>
      <Box className='toolbar' sx={{ borderBottom: `1px solid ${mainTheme.palette.secondary.main}`, mb: 2 }}>
        <Box fontSize='24px' fontWeight='bold' color={mainTheme.palette.secondary.main} pb={0.5}>EVENTS</Box>
        {/* <Box sx={{ mr: -1, display: 'flex' }}>
          <IconButton size='small' onClick={() => 1}>
            <FilterAltIcon />
          </IconButton>
        </Box> */}
      </Box>
      <Stack direction='row' sx={{ pl: 1, pr: 1 }}>
        <Box width='25%' className='sortBox' onClick={()=> renderSort('name')}><Box className='sortLabel'>Name</Box>{sortArrow('name')}</Box>
        <Box width='25%' className='sortBox' onClick={()=> renderSort('promoter.name')}><Box className='sortLabel'>Promoter</Box>{sortArrow('promoter.name')}</Box>
        <Box width='13%' className='sortBox' onClick={()=> renderSort('startDate')}><Box className='sortLabel'>Start Date</Box>{sortArrow('startDate')}</Box>
        <Box width='13%' className='sortBox' onClick={()=> renderSort('endDate')}><Box className='sortLabel'>End Date</Box>{sortArrow('endDate')}</Box>
        <Box width='8%' className='sortBox' onClick={()=> renderSort('totalStreams')}><Box className='sortLabel'>Streams</Box>{sortArrow('totalStreams')}</Box>
        <Box width='8%' className='sortBox' onClick={()=> renderSort('totalDevices')}><Box className='sortLabel'>Devices</Box>{sortArrow('totalDevices')}</Box>
        <Box width='8%' className='sortBox' onClick={()=> renderSort('totalLogs')}><Box className='sortLabel'>Logs</Box>{sortArrow('totalLogs')}</Box>
      </Stack>
        <Scrollbars
          style={{height: '78vh', padding: 16, paddingTop: 0, marginLeft: -16}}
          onScroll={({ target }) => handlePagination(target.scrollTop, target.getBoundingClientRect().height, target.scrollHeight)}
        >
          <Box p={2} pt={0}>
            { events ? events.map(x => <CutListRow key={x._id} element={x} openFunc={openSingleCutList} />) : <LinearProgress color='secondary' sx={{ height: '20px' }} /> }
          </Box>
        </Scrollbars>
      { showError.show ?<ErrorDialog text={showError.message} closeFunc={setShowError} /> : null }
    </Paper>
  )
}

export default EventList