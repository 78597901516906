import { API } from '../config/constants.js'
import headers from './headers.js'
import apiRequest from './request.js'


export const getAll = async (body) => {
  return apiRequest(`${API}/stream/log/events`, {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(body)
  })
}


export const getByEvent = async (body) => {
  return apiRequest(`${API}/stream/log/streams`, {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(body)
  })
}


export const singleStream = async (body) => {
  return apiRequest(`${API}/stream/log/single`, {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(body)
  })
}


export const streamDetails = async (id) => {
  return apiRequest(`${API}/stream/log/details/${id}`, {
    method: 'GET',
    headers: headers()
  })
}


