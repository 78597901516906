/**
 * Make request to API
 * @param { string } address 
 * @param { { method: 'POST' | 'GET' | 'PUT' | 'DELETE', headers: object, body: object } } options 
 */
const apiRequest = async (address, options) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(address, options)
      .then(res => {
        if (res.status === 401 || res.status === 403) throw new Error('Access denied!')
        return res
      })
      .then(res => res.json())
      
      if (!response.success) throw new Error(response.message)
      if (response.user && response.token) return resolve({ user: response.user, token: response.token })

      return resolve(response.payload)
    } catch (error) {
      reject(error)
    }
  })
}

export default apiRequest