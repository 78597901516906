import * as React from 'react'
import { Box, IconButton, Dialog, Stack } from '@mui/material'
import Slide from '@mui/material/Slide'
import CloseIcon from '@mui/icons-material/Close'
import mainTheme from '../../theme/MainTheme'
import { statTime } from '../../shared/time'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const StatDialog = ({ data, closeFunc }) => {
  const camWidth = 70 / data.camsCount.length

  const bitrateData = Object.values(data.bitrateData).map(x => x.map(s => Number(s.value.split(' ')[0])))
  const bitrateMinMax = bitrateData.map(x => ({ min: Math.min(...x), max: Math.max(...x) }))

  const speedData = Object.values(data.speeds).map(x => x.map(s => Number(s.value)))
  const speedMinMax = speedData.map(x => ({ min: Math.min(...x), max: Math.max(...x) }))

  const connectionsData = Object.values(data.connections).map(x => ({ con: x.filter(s => s.value === 'connected').length, disc: x.filter(s => s.value !== 'connected').length }))
  
  const volumeData = Object.values(data.volumes).map(x => Number(x.value))
  const volumeMinMax = volumeData.length
    ? data.camsCount.map(() => ({ min: Math.min(...volumeData), max: Math.max(...volumeData) })) 
    : data.camsCount.map(() => ({ min: '?', max: '?' }))

  const ngxData = Object.values(data.ngxData).map(x => x.filter(s => s.value === 'no data' || s.value === 0).length)

  return (
    <Dialog maxWidth='md' open={true} TransitionComponent={Transition} keepMounted fullWidth>
      <Box p={2} pt={1}>
        <Box display='flex' justifyContent='space-between' borderBottom={`1px solid ${mainTheme.palette.secondary.main}`} alignItems='center' mb={1}>
          <Box fontSize={20} fontWeight={500} color={mainTheme.palette.secondary.main}>STREAM STATISTICS</Box>
          <IconButton sx={{mr: -1}} color='secondary' onClick={() => closeFunc(false)}><CloseIcon /></IconButton>
        </Box>
        <Box width='100%' display='flex'>
          <Box minWidth='33%'>
            <Box fontWeight={500} mb={2}>MAIN STATISTICS</Box>
            <Stack direction='row' mb={1}>
              <Box minWidth='50%'>Start Time</Box><Box minWidth='50%'>{ statTime(data.startTime) }</Box>
            </Stack>
            <Stack direction='row' mb={1}>
              <Box minWidth='50%'>End Time</Box><Box minWidth='50%'>{ statTime(data.endTime) }</Box>
            </Stack>
            <Stack direction='row' mb={1}>
              <Box minWidth='50%'>Real Start Time</Box><Box minWidth='50%'>{ data.startedAt ? statTime(data.startedAt) : '-' }</Box>
            </Stack>
            <Stack direction='row' mb={1}>
              <Box minWidth='50%'>Real End Time</Box><Box minWidth='50%'>{ data.endedAt ? statTime(data.endedAt) : '-' }</Box>
            </Stack>
            <Stack direction='row' mb={1}>
              <Box minWidth='50%'>Total visitors</Box><Box minWidth='50%'>{ data.visitors }</Box>
            </Stack>
            <Stack direction='row' mb={1}>
              <Box minWidth='50%'>Unique visitors</Box><Box minWidth='50%'>{ data.uniqueVisitors.length + data.uniqueVisitorsLowTime.length }</Box>
            </Stack>
            <Stack direction='row' mb={1}>
              <Box minWidth='50%'>Cams number</Box><Box minWidth='50%'>{ data.cams.length }</Box>
            </Stack>
            <Stack direction='row' mb={1}>
              <Box minWidth='50%'>Total logs</Box><Box minWidth='50%'>{ data.streamLogs.reduce((acc, el) => acc + el.totalLogs, 0) }</Box>
            </Stack>
          </Box>
          <Box width='32px' display='flex' justifyContent='center'>
            <Box borderLeft='1px solid #d8d8d8' />
          </Box>
          <Box minWidth='calc((67% - 32px))'>
            <Box fontWeight={500} mb={2}>LOGS STATISTICS</Box>
              <Stack direction='row' mb={1}>
                <Box minWidth='30%'/>
                { data.camsCount.map(x =><Box sx={{ fontWeight: 500 }} key={x} minWidth={`${camWidth}%`}>Cam {x}</Box>) }
              </Stack>
              <Stack direction='row' mb={1}>
                <Box minWidth='30%'>Bitrate</Box>
                {
                  bitrateMinMax.map((el, index) => 
                    <Box key={index} minWidth={`${camWidth}%`}>
                      { el.min !== Infinity ? el.min : '?'} - {el.max !== -Infinity ? el.max : '?' }
                    </Box>)
                }
              </Stack>
              <Stack direction='row' mb={1}>
                <Box minWidth='30%'>Speed</Box>
                {
                  speedMinMax.map((el, index) => 
                    <Box key={index} minWidth={`${camWidth}%`}>
                      {el.min !== Infinity ? el.min : '?'} - {el.max !== -Infinity ? el.max : '?'}
                    </Box>)
                }
              </Stack>
              <Stack direction='row' mb={1}>
                <Box minWidth='30%'>Con. / Disc.</Box>
                { connectionsData.map((el, index) => <Box key={index} minWidth={`${camWidth}%`}>{el.con} - {el.disc}</Box>) }
              </Stack>
              <Stack direction='row' mb={1}>
                <Box minWidth='30%'>Volume</Box>
                {
                  volumeMinMax.map((el, index) => 
                    <Box key={index} minWidth={`${camWidth}%`}>
                      { el.min !== Infinity ? el.min : '?'} - {el.max !== -Infinity ? el.max : '?' }
                    </Box>)
                }
              </Stack>
              <Stack direction='row' mb={1}>
                <Box minWidth='30%'>NGX Problems</Box>
                { ngxData.map((el, index) => <Box key={index} minWidth={`${camWidth}%`}>{ el }</Box>) }
              </Stack>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}


export default StatDialog