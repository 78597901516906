import React, { useState, forwardRef } from 'react'
import { Box, IconButton, Dialog, Slide, Tabs, Tab } from '@mui/material'
import { LineChart } from '@mui/x-charts/LineChart'
import CloseIcon from '@mui/icons-material/Close'
import mainTheme from '../../theme/MainTheme'
import moment from 'moment'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})


const ChartDialog = ({ data, closeFunc }) => {
  const charts = {
    '1': {
      series: Object.keys(data.speeds).map(c => ({ label: `Cam ${c}`, curve: 'linear', data: data.speeds[c].map(x => Number(x.value)) })),
      xAxis: Object.values(data.speeds).map(el => ({ data: el.map(s => Number(moment(s.time).unix())), valueFormatter: (date) => moment(date).format('mm:ss').toString() }))
    },
    '2': {
      series: Object.keys(data.bitrateData).map(c => ({ label: `Cam ${c}`, curve: 'linear', data: data.bitrateData[c].map(x => Number(x.value.split(' ')[0])) })),
      xAxis: Object.values(data.bitrateData).map(el => ({ data: el.map(s => Number(moment(s.time).unix())), valueFormatter: (date) => moment(date).format('mm:ss').toString() }))
    },
    '3': {
      series: Object.keys(data.connections).map(c => ({ label: `Cam ${c}`, curve: 'linear', data: data.connections[c].map(x => x.value === 'connected' ? 1 : -1) })),
      xAxis: Object.values(data.connections).map(el => ({ data: el.map(s => Number(moment(s.time).unix())), valueFormatter: (date) => moment(date).format('mm:ss').toString() }))
    },
    '4': {
      series: Object.keys(data.ngxData).map(c => ({ label: `Cam ${c}`, curve: 'linear', data: data.ngxData[c].map(x => x.value === 'no data' ? -1 : x.value > 0 ? 1 : 0) })),
      xAxis: Object.values(data.ngxData).map(el => ({ data: el.map(s => Number(moment(s.time).unix())), valueFormatter: (date) => moment(date).format('mm:ss').toString() }))
    },
    '5': {
      series: [{ data: data.volumes.map(x => (x.value)) }],
      xAxis: [{ data: data.volumes.map(x => (Number(moment(x.time).unix()))), valueFormatter: (date) => moment(date).format('mm:ss').toString() }],
    }
  }

  const [tab, setTab] = useState(1)
  const [chartData, setChartData] = useState(charts[1])

  const changeTab = (_, newValue) => {
    setTab(newValue)
    setChartData(charts[newValue])
  }

  return (
    <Dialog maxWidth='md' open={true} TransitionComponent={Transition} keepMounted fullWidth>
      <Box p={2} pt={1}>
        <Box display='flex' justifyContent='space-between' borderBottom={`1px solid ${mainTheme.palette.secondary.main}`} alignItems='center' mb={1}>
          <Box fontSize={20} fontWeight={500} color={mainTheme.palette.secondary.main}>CHARTS</Box>
          <IconButton sx={{mr: -1}} color='secondary' onClick={() => closeFunc(false)}><CloseIcon /></IconButton>
        </Box>
        <Tabs
          value={tab}
          onChange={changeTab}
          textColor='primary'
          indicatorColor='secondary'
          sx={{ borderBottom: `1px solid ${mainTheme.palette.secondary.main}`, mb: 2 }}
        >
          <Tab disabled={!Object.values(data.speeds).some(x => x.length)}  value={1} label={`Speed`} />
          <Tab disabled={!Object.values(data.bitrateData).some(x => x.length)} value={2} label={`Bitrate`} />
          <Tab disabled={!Object.values(data.connections).some(x => x.length)} value={3} label={`Connections`} />
          <Tab disabled={!Object.values(data.ngxData).some(x => x.length)} value={4} label={`NGX Data`} />
          <Tab disabled={!data.volumes.length} value={5} label={`Volume`} />
        </Tabs>
        <LineChart
          xAxis={chartData.xAxis}
          series={chartData.series}
          height={400}
          margin={{ left: 30, right: 30, top: 50, bottom: 30 }}
          grid={{ vertical: true, horizontal: true }}
        />
      </Box>
    </Dialog>
  )
}

export default ChartDialog