import React, { useState, useRef, useEffect } from 'react'
import { Box, Paper, IconButton, Stack, Collapse, LinearProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { MODE } from '../../config/constants'
import mainTheme from '../../theme/MainTheme'
import ErrorDialog from '../ErrorDialog/ErrorDialog'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { getByEvent } from '../../api/logs'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ReadMoreIcon from '@mui/icons-material/ReadMore'
import CloseIcon from '@mui/icons-material/Close'
import { rotateAngle } from '../../shared/sortFunctions'


const RowBox = ({ children }) => <Box sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', mr: 2 }}>{children}</Box>

const StreamRow = ({ element }) => {
  const [open, setOpen] = useState(false)
  const history = useNavigate()

  return (
    <Paper>
      <Stack direction='row' sx={{p: 1, mt: 1 }}>
        <Box width='5%'>
          <IconButton size='small' color='secondary' onClick={() => history(`/stream/${element._id}`)}>
            <ReadMoreIcon />
          </IconButton>
        </Box>
        <Box className='streamCell' width='8%'><RowBox>{element.arenaNumber}</RowBox></Box>
        <Box className='streamCell' width='25%'><RowBox>{element.description}</RowBox></Box>
        <Box className='streamCell' width='7%'><RowBox>{element.camsNumber || '-'}</RowBox></Box>
        <Box className='streamCell' width='14%'><RowBox>{element.videoPlayerProblems}</RowBox></Box>
        <Box className='streamCell' width='16%'><RowBox>{element.operatorActions}</RowBox></Box>
        <Box className='streamCell' width='20%'><RowBox>{element.totalUniqueViews}</RowBox></Box>
        <Box className='streamCell' width='5%' display='flex' justifyContent='right'>
          <IconButton size='small' onClick={() => setOpen(!open)}>
            { open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
          </IconButton>
        </Box>
      </Stack>
      <Collapse orientation='vertical' in={open} collapsedSize={0}>
        <Box sx={{ borderTop: `1px solid ${mainTheme.palette.primary.middle}`, mr: 1, ml: 1, mb: 2 }}></Box>
        <Box sx={{ p: 1, pt: 0, mt: 1 }}>
          <Stack direction='row' sx={{p: 0, mt: 0.5, borderBottom: `1px solid ${mainTheme.palette.secondary.main}` }}>
            <Box width='6%' sx={{ backgroundColor: mainTheme.palette.secondary.main, color: 'white', p: 0.7, fontWeight: 500, textAlign: 'center', mr: 2 }}>CAMERAS</Box>
            <Box className='camCell' width='8%'><RowBox>Number</RowBox></Box>
            <Box className='camCell' width='10%'><RowBox>Speed Logs</RowBox></Box>
            <Box className='camCell' width='10%'><RowBox>Bitrate Logs</RowBox></Box>
            <Box className='camCell' width='15%'><RowBox>Device Connected Times</RowBox></Box>
            <Box className='camCell' width='17%'><RowBox>Device Disconnected Times</RowBox></Box>
            <Box className='camCell' width='8%'><RowBox>Min Speed</RowBox></Box>
            <Box className='camCell' width='8%'><RowBox>Max Speed</RowBox></Box>
            <Box className='camCell' width='9%'><RowBox>Min Bitrate</RowBox></Box>
            <Box className='camCell' width='9%'><RowBox>Max Bitrate</RowBox></Box>
          </Stack>
          {
            element.cams.map(x => {
              return (
                <Stack key={x.number} direction='row' sx={{p: 0, mt: 1 }}>
                  <Box width='6%' sx={{ pl: 0.7, pr: 0.7, mr: 2 }} />
                  <Box className='streamCell' width='8%'><RowBox>{x.number}</RowBox></Box>
                  <Box className='streamCell' width='10%'><RowBox>{x.speedLogs}</RowBox></Box>
                  <Box className='streamCell' width='10%'><RowBox>{x.bitrateLogs}</RowBox></Box>
                  <Box className='streamCell' width='15%'><RowBox>{x.deviceConnectedTimes}</RowBox></Box>
                  <Box className='streamCell' width='17%'><RowBox>{x.deviceDisconnectedTimes}</RowBox></Box>
                  <Box className='streamCell' width='8%'><RowBox>{x.minSpeed || '-'}</RowBox></Box>
                  <Box className='streamCell' width='8%'><RowBox>{x.maxSpeed || '-'}</RowBox></Box>
                  <Box className='streamCell' width='9%'><RowBox>{x.minBitrate || '-'}</RowBox></Box>
                  <Box className='streamCell' width='9%'><RowBox>{x.maxBitrate || '-'}</RowBox></Box>
                </Stack>
              )
            })
          }
        </Box>
      </Collapse>
    </Paper>
  )
}


const StreamList = () => {
  const { id: eventId } = useParams()
  const firstRenderRef = useRef(true)
  const [showError, setShowError] = useState({ show: false, message: '' })
  const [data, setData] = useState(null)
  const [sort, setSort] = useState({ arenaNumber: 1 })
  const history = useNavigate()
  

  const renderSort = (field) => {
    let newSort = { [field]: 1 }

    if (!(field in sort)) {
      newSort = { [field]: -1 }
    } else {
      newSort = sort[field] === 1 ? { [field]: -1 } : { [field]: 1 }
    }

    setSort(newSort)

    if (newSort[field] === 1) {
      setData({ ...data, streams: data.streams.sort((a, b) => a[field] - b[field]) })
    } else {
      setData({ ...data, streams: data.streams.sort((a, b) => b[field] - a[field]) })
    }
  }

  const sortArrow = (field) => {
    if (!(field in sort)) return null
    return sort[field] === 1 ? <KeyboardArrowUpIcon color='primary' sx={rotateAngle(true)}/> : <KeyboardArrowDownIcon color='primary' sx={rotateAngle(false)}/>
  }

  useEffect(() => {
    if (firstRenderRef.current && MODE === 'development') {
      firstRenderRef.current = false
      return
    }

    getByEvent({ event: eventId })
      .then(res => setData(res))
      .catch(e => setShowError({ show: true, message: e.message }))
  }, [eventId])

  return (
    <Paper sx={{p: 2, m: 2, fontSize: '14px' }}>
      <Box className='toolbar' sx={{ borderBottom: `1px solid ${mainTheme.palette.secondary.main}`, mb: 2 }}>
        <Box fontSize='24px' fontWeight='bold' color={mainTheme.palette.secondary.main} pb={0.5}>
          { data ? data.name : '-' }
        </Box>
        <Box sx={{ mr: -1, display: 'flex' }}>
          <IconButton size='small' color='secondary' onClick={() => history(`/events`)}><CloseIcon /></IconButton>
        </Box>
      </Box>
      <Stack direction='row' sx={{ pl: 1, pr: 1 }}>
        <Box width='5%' />
        <Box width='8%' className='sortBox' onClick={()=> renderSort('arenaNumber')}><Box className='sortLabel'>Arena</Box>{sortArrow('arenaNumber')}</Box>
        <Box width='25%' className='sortBox' onClick={()=> renderSort('description')}><Box className='sortLabel'>Description</Box>{sortArrow('description')}</Box>
        <Box width='7%' className='sortBox' onClick={()=> renderSort('camsNumber')}><Box className='sortLabel'>Cams</Box>{sortArrow('camsNumber')}</Box>
        <Box width='14%' className='sortBox' onClick={()=> renderSort('videoPlayerProblems')}><Box className='sortLabel'>VideoProblems</Box>{sortArrow('videoPlayerProblems')}</Box>
        <Box width='16%' className='sortBox' onClick={()=> renderSort('operatorActions')}><Box className='sortLabel'>Operator Actions</Box>{sortArrow('operatorActions')}</Box>
        <Box width='20%' className='sortBox' onClick={()=> renderSort('totalUniqueViews')}><Box className='sortLabel'>Total Unique Views</Box>{sortArrow('totalUniqueViews')}</Box>
        <Box width='5%' />
      </Stack>
      <Scrollbars style={{height: '78vh', padding: 16, paddingTop: 0, marginLeft: -16}}>
        <Box p={2} pt={0}>
          { data === null ? <LinearProgress color='secondary' sx={{ height: '20px' }} /> : data.streams.map(x => <StreamRow key={x._id} element={x} />) }
        </Box>
      </Scrollbars>
      { showError.show ?<ErrorDialog text={showError.message} closeFunc={setShowError} /> : null }
    </Paper>
  )
}

export default StreamList