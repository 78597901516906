import React, { useState, useContext, useEffect, useRef } from 'react'
import { Box, Stack , Paper, TextField, InputAdornment, Button, IconButton, LinearProgress } from '@mui/material'
import { login } from '../../api/user'
import { MODE } from '../../config/constants'
import { useNavigate } from 'react-router-dom'
import { storeCredentials, getCredentials } from '../../config/storage'
import validator from 'validator'
import UserContext from '../../shared/UserContext'
import ErrorDialog from '../ErrorDialog/ErrorDialog'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'


const Login = () => {
  const { user, setUser } = useContext(UserContext)
  const firstRenderRef = useRef(true)

  const [email, setEmail] = useState({ value: '', error: false })
  const [password, setPassword] = useState({ value: '', error: false })
  const [showError, setShowError] = useState({ show: false, message: '' })
  const [passType, setPassType] = useState(true)
  const [connect, setConnect] = useState(false)
  
  const history = useNavigate()

  const changeEmail = (value) => setEmail({ value, error: false })
  const changePassword = (value) => setPassword({ value, error: false })

  useEffect(() => {
    if (firstRenderRef.current && MODE === 'development') {
      firstRenderRef.current = false
      return
    }

    if (user && getCredentials()) {
      setUser(user)
      history('/events')
    }
  }, [user, history, setUser])

  const userLogin = () => {
    let emailError = false
    let passwordError = false
    const errors = []

    if (!email.value.trim()) {
      errors.push('Email field is empty!')
      emailError = true
    } else if (!validator.isEmail(email.value.trim())) {
      errors.push('Email address is not valid!')
      emailError = true
    }

    if (!password.value.trim()) {
      errors.push('Password field is empty!')
      passwordError = true
    }

    if (emailError || passwordError) {
      setEmail({ ...email, error: emailError })
      setPassword({ ...password, error: passwordError })
      setShowError({ show: true, message: errors })
      return
    }

    const body = { email: email.value.trim(), password: password.value.trim() }
    setConnect(true)
    login(body)
      .then(res => {
        setConnect(false)
        storeCredentials(res)
        setUser(res.user)
      })
      .catch(e => {
        setConnect(false)
        setShowError({ show: true, message: e.message })
      })
  }

  return (
    <Paper sx={{ m: 4, height: 'calc(100vh - 64px)', display: 'flex', justifyContent: 'center' }} elevation={3}>
      <Box sx={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Stack  sx={{ width: '70%' }} gap={3}>
          <Box sx={{ width: '100%', minHeight: '4px' }}>{ connect ? <LinearProgress /> : null }</Box>
          <TextField
            color='primary'
            size='small'
            label='E-mail'
            variant='outlined'
            autoComplete='off'
            disabled={connect}
            error={email.error}
            value={email.value}
            onChange={(e) => changeEmail(e.target.value)}
          />
          <TextField
            InputProps={{ endAdornment:
              <InputAdornment position='end'>
                <IconButton size='small' sx={{ mr: -1 }} onClick={() => setPassType(!passType)} disabled={connect}>
                  { passType ? <VisibilityOffIcon color='primary' /> : <VisibilityIcon color='primary' /> }
                </IconButton>
              </InputAdornment>
            }}
            type={ passType ? 'password' : 'text' }
            size='small'
            label='Password'
            variant='outlined'
            autoComplete='off'
            disabled={connect}
            error={password.error}
            value={password.value}
            onChange={(e) => changePassword(e.target.value)}
          />
          <Button disabled={connect} sx={{ color: 'white' }} color='secondary' variant='contained' onClick={userLogin}>Login</Button>
        </Stack>
      </Box>
      { showError.show ?<ErrorDialog text={showError.message} closeFunc={setShowError} /> : null }
    </Paper>
  )
}

export default Login