import React from 'react'
import { Dialog, Divider, Slide, Button, Stack, Box } from '@mui/material'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'
import MainTheme from '../../theme/MainTheme'

const orange = MainTheme.palette.secondary.main

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const ErrorDialog = ({ text, closeFunc }) => {
  return (
    <Dialog fullWidth open={true} TransitionComponent={Transition} keepMounted >
      <Box sx={{ borderTop: `8px solid ${orange}` }}>
      <Stack direction='row' sx={{ p: 2 }} gap={2}>
        <ReportGmailerrorredIcon sx={{ fontSize: 120, color: orange, mt: -1 }} />
        <Box>
          <Box fontSize={22} fontWeight='bold' mb={1}>ERROR</Box>
          <Box>
            {
              Array.isArray(text)
                ? text.map((el, index) => <Box key={index}>{el}</Box>)
                : text
            }
            
          </Box>
        </Box>
      </Stack>
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', p: 2, justifyContent: 'center' }}>
        <Button sx={{ minWidth: '120px' }} variant='contained' onClick={() => closeFunc({ show: false, message: '' })}>Затвори</Button>
      </Box>
    </Dialog>
  )
}

export default ErrorDialog