export const rotateAngle = (direction) => {
  return {
    animation: 'spin 0.3s linear',
  '@keyframes spin': {
    '0%': {
      transform: `rotate(${ direction ? 180 : -180}deg)`,
    },
    '100%': {
      transform: `rotate(0deg)`,
    },
  },
  }
}