import React, { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import UserContext from './shared/UserContext.js'
import Login from './components/Login/Login'
import mainTheme from './theme/MainTheme.js'
import EventList from './components/EventList/EventList.jsx'
import StreamList from './components/StreamList/StreamList.jsx'
import Stream from './components/Stream/Stream.jsx'
import './App.css';

function App() {
  const [ user, setUser ] = useState(JSON.parse(localStorage.getItem('user')) || null)

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <ThemeProvider theme={mainTheme}>
        <BrowserRouter>
          <Routes>
            <Route exact path='/' element={<Login />} />
            <Route exact path='/events' element={<EventList />} />
            <Route exact path='/event/:id' element={<StreamList />} />
            <Route exact path='/stream/:id' element={<Stream />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
  </UserContext.Provider>
  )
}

export default App
