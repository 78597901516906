import { createTheme } from '@mui/material/styles'
import { red, grey, pink } from '@mui/material/colors'


const mainTheme = createTheme({
  palette: {
    primary: {
      main: grey[700],
      middle: grey[300],
      light: grey[200],
      middleLight: grey[100],
      superLight: grey[50],
    },
    secondary: {
      main: red[700],
      middle: red[500],
      light: red[200],
      middleLight: red[100],
      superLight: red[50],
      contrastText: '#FFF'
    },
    error: {
      main: pink[200],
      middle: pink[100],
      light: pink[100],
      middleLight: pink[100],
      superLight: pink[50],
      contrastText: '#FFF'
    }
  }
})

export default mainTheme