import React, { useState, useRef, useEffect } from 'react'
import { Box, Paper, IconButton, Stack, Tabs, Tab, Tooltip, Badge, LinearProgress, Menu, FormControl, InputLabel, Select, OutlinedInput } from '@mui/material'
import { ListItemText, MenuItem, Checkbox, Button } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { MODE } from '../../config/constants'
import { rotateAngle } from '../../shared/sortFunctions'
import mainTheme from '../../theme/MainTheme'
import ErrorDialog from '../ErrorDialog/ErrorDialog'
import { Scrollbars } from 'react-custom-scrollbars-2'
import CloseIcon from '@mui/icons-material/Close'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import BarChartIcon from '@mui/icons-material/BarChart'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { singleStream, streamDetails } from '../../api/logs'
import { filterMenuStyle } from '../../shared/styles'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import LogRow from './LogRow'
import moment from 'moment'
import StatDialog from './StatDialog'
import ChartDialog from './ChartDialog'


const Stream = () => {
  const history = useNavigate()
  const { id: streamId } = useParams()
  const firstRenderListRef = useRef(true)
  const firstDetailsRef = useRef(true)
  const [query, setQuery] = useState({ pageNumber: 1, pageSize: 20, sortBy: { createdAt: -1 }, type: [], startTime: null, endTime: null, noPagination: false,  hasNextPage: false })
  const [showError, setShowError] = useState({ show: false, message: '' })
  const [logs, setLogs] = useState(null)
  const [cam, setCam] = useState(1)
  const [camsCount, setCamsCount] = useState([1])
  const [details, setDetails] = useState(null)
  const [haveFilter, setHaveFilter] = useState(false)
  const [filterAnchor, setFilterAnchor] = useState(null)
  const [selectedTypes, setSelectedTypes] = useState([])
  const [typesFilter, setTypesFilter] = useState([])
  const [datesFilter, setDatesFilter] = useState({ minDate: null, maxDate: null })
  const [showStatDialog, setShowStatDialog] = useState(false)
  const [showChartDialog, setShowChartDialog] = useState(false)

  const openFilter = Boolean(filterAnchor)

  const changeTypeFilter = (event) => {
    const { target: { value } } = event
    setSelectedTypes(typeof value === 'string' ? value.split(',') : value)
  }

  const changeCam = (_, newValue) => {
    setLogs(null)
    setQuery({ ...query, pageNumber: 1, hasNextPage: false })
    setCam(newValue)
  }

  const renderSort = (field) => {
    const newQuery = { ...query, pageNumber: 1, hasNextPage: false }
    if (!(field in query.sortBy)) {
      newQuery.sortBy =  { [field]: -1 }
    } else {
      query.sortBy[field] === 1
      ? newQuery.sortBy = { [field]: -1 }
      : newQuery.sortBy = { [field]: 1 }
    }
    
    setLogs(null)
    setQuery(newQuery)
  }

  const sortArrow = (field) => {
    if (!(field in query.sortBy)) return null
    return query.sortBy[field] === 1 ? <KeyboardArrowUpIcon color='primary' sx={rotateAngle(true)}/> : <KeyboardArrowDownIcon color='primary' sx={rotateAngle(false)}/>
  }

  const handlePagination = (scrollTop, height, scrollHeight) => {
    if (scrollTop + height < scrollHeight - 20) return
    if (query.hasNextPage) setQuery({ ...query, pageNumber: query.pageNumber + 1, hasNextPage: false })
  }

  const applyFilter = () => {
    const newQuery = {
      ...query,
      startTime: datesFilter.minDate.toString() !== details.minDate.toString() ? moment(datesFilter.minDate).toDate() : null,
      endTime: datesFilter.maxDate.toString() !== details.maxDate.toString() ? moment(datesFilter.maxDate).toDate() : null,
      type: selectedTypes.length ? selectedTypes : [],
      pageNumber: 1,
      hasNextPage: false
    }

    setLogs(null)
    setHaveFilter(newQuery.startTime || newQuery.endTime || newQuery.type.length)
    setQuery(newQuery)
    setFilterAnchor(null)
  }

  const clearFilter = () => {
    if (haveFilter) {
      const newQuery = {
        ...query,
        startTime: null,
        endTime: null,
        type: [],
        pageNumber: 1,
        hasNextPage: false
      }
  
      setHaveFilter(false)
      setLogs(null)
      setQuery(newQuery)
    }

    setSelectedTypes([])
    setDatesFilter({ minDate: details.minDate, maxDate: details.maxDate })
    setFilterAnchor(null)
  }

  useEffect(() => {
    if (firstDetailsRef.current && MODE === 'development') {
      firstDetailsRef.current = false
      return
    }

    streamDetails(streamId)
      .then(res => {
        setDetails(res)
        setCamsCount(res.camsCount)
        setTypesFilter(res.streamLogs.map(x => x._id).sort())
        setDatesFilter({ minDate: res.minDate, maxDate: res.maxDate })
      })
      .catch(e => setShowError({ show: true, message: e.message }))
  }, [streamId])

  useEffect(() => {
    if (firstRenderListRef.current && MODE === 'development') {
      firstRenderListRef.current = false
      return
    }

    const body = { stream: streamId, cam, type: query.type, startTime: query.startTime, endTime: query.endTime, pageNumber: query.pageNumber, pageSize: 20, sortBy: { ...query.sortBy } }

    singleStream(body)
      .then(res => {
        setLogs(logs => query.pageNumber === 1 ? res.docs : [ ...logs, ...res.docs ])
        setQuery(query => ({ ...query, pageNumber: res.page, hasNextPage: res.hasNextPage }))
      })
      .catch(e => setShowError({ show: true, message: e.message }))
  }, [query.pageNumber, query.sortBy, streamId, cam, query.type, query.startTime, query.endTime])


  return (
    <Paper sx={{p: 2, m: 2, fontSize: '14px' }}>
      {
        details === null
          ? <LinearProgress color='secondary' sx={{ height: '37px' }} />
          : <Box className='toolbar' sx={{ borderBottom: `1px solid ${mainTheme.palette.secondary.main}`, mb: 0 }}>
              <Box fontSize='24px' fontWeight='bold' color={mainTheme.palette.secondary.main} pb={0.5}>
                { `${details.event.arenaType} ${details.arenaNumber} ${details.description ? `- ${details.description}` : ''} ` }
              </Box>
              <Box sx={{ mr: -1, display: 'flex' }}>
                <Tooltip title='Chart' arrow>
                  <IconButton size='small' color='primary' onClick={() => setShowChartDialog(true)}>
                    <BarChartIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Details' arrow>
                  <IconButton size='small' color='primary' onClick={() => setShowStatDialog(true)}>
                    <TextSnippetIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Filters' arrow>
                  <IconButton size='small' color='primary' onClick={(event) => setFilterAnchor(event.currentTarget)}>
                    <Badge color="secondary" variant='dot' invisible={!haveFilter}><FilterAltIcon /></Badge>
                  </IconButton>
                </Tooltip>
                <Tooltip title='Back' arrow>
                  <IconButton size='small' color='primary' onClick={() => history(`/event/${details.event._id}`)}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
      }
      <Tabs
        value={cam}
        onChange={changeCam}
        textColor='secondary'
        indicatorColor='secondary'
        sx={{ borderBottom: `1px solid ${mainTheme.palette.secondary.main}`, mb: 2 }}
      >
        { camsCount.map(x => <Tab key={x} value={x} label={`Camera ${x}`} />) }
      </Tabs>
      <Stack direction='row' sx={{ pl: 1, pr: 1 }}>
        <Box width='10%' className='sortBox' onClick={()=> renderSort('createdAt')}><Box className='sortLabel'>Time</Box>{sortArrow('createdAt')}</Box>
        <Box width='25%' className='sortBox' onClick={()=> renderSort('type')}><Box className='sortLabel'>Type</Box>{sortArrow('type')}</Box>
        <Box width='30%' className='sortBox' onClick={()=> renderSort('deviceCurrentName')}><Box className='sortLabel'>Device Name</Box>{sortArrow('deviceCurrentName')}</Box>
        <Box width='30%' className='sortBox' onClick={()=> renderSort('mainValue')}><Box className='sortLabel'>Main Value</Box>{sortArrow('mainValue')}</Box>
        <Box width='5%' />
      </Stack>
      <Scrollbars
          style={{height: '76vh', padding: 16, paddingTop: 0, marginLeft: -16}}
          onScroll={({ target }) => handlePagination(target.scrollTop, target.getBoundingClientRect().height, target.scrollHeight)}
        >
        <Box p={2} pt={0}>
          {
            logs === null
              ? <LinearProgress color='secondary' sx={{ height: '20px' }} />
              : logs.map(x => <LogRow key={x._id} element={x} />)
          }
        </Box>
      </Scrollbars>
      <Menu
        anchorEl={filterAnchor}
        open={openFilter}
        MenuListProps={{ disablePadding: true }}
        slotProps={filterMenuStyle}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Stack spacing={2} p={1} m={1}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id='tagTypeFilter' size='small'>Type</InputLabel>
            <Select
              size='small'
              labelId='tagTypeFilter'
              multiple
              value={selectedTypes}
              onChange={changeTypeFilter}
              input={<OutlinedInput label='Type' />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={{ PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250 } } }}
            >
              {
                typesFilter.map((name) => (
                  <MenuItem key={name} value={name} sx={{height: 32}}>
                    <Checkbox size='small' checked={selectedTypes.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              label='Start Time'
              format='HH:mm - DD-MM'
              ampm={false}
              slotProps={{ textField: { size: 'small' } }}
              onChange={(date) => setDatesFilter({ ...datesFilter, minDate: date })}
              minDateTime={moment(details?.minDate)}
              maxDateTime={moment(datesFilter.maxDate)}
              value={moment(datesFilter.minDate)}
            />
            <DateTimePicker
              label='End Time'
              format='HH:mm - DD-MM'
              ampm={false}
              slotProps={{ textField: { size: 'small' } }}
              onChange={(date) => setDatesFilter({ ...datesFilter, maxDate: date })}
              minDateTime={moment(datesFilter.minDate)}
              maxDateTime={moment(details?.maxDate)}
              value={moment(datesFilter.maxDate)}
            />
          </LocalizationProvider>
          <Box display='flex' justifyContent='space-between'>
            <Button variant='contained' sx={{width: '100%'}} size='small' onClick={applyFilter}>Apply</Button>
            <Box width='32px' />
            <Button variant='contained' sx={{width: '100%'}} color='secondary' size='small' onClick={clearFilter}>Clear</Button>
          </Box>
        </Stack>
      </Menu>
      { showError.show ?<ErrorDialog text={showError.message} closeFunc={setShowError} /> : null }
      { showStatDialog ? <StatDialog data={details} closeFunc={setShowStatDialog} /> : null }
      { showChartDialog ? <ChartDialog data={details} closeFunc={setShowChartDialog} /> : null }
    </Paper>
  )
}

export default Stream