import React, { useState } from 'react'
import { Box, Paper, IconButton, Stack, Tooltip } from '@mui/material'
import ListAltIcon from '@mui/icons-material/ListAlt'
import { hour } from '../../shared/time'
import Menu from '@mui/material/Menu'
import Divider from '@mui/material/Divider'
import { menuStyle } from '../../shared/styles'


const RowBox = ({ children }) => <Box sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', mr: 2 }}>{children}</Box>

const LogRow = ({ element }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  if (element.type === 'videoPlayer' && element.metadata.success === false) {
    if (typeof element.metadata.error !== 'object') element.metadata.error = JSON.parse(element.metadata.error).error
  }

  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return (
    <Paper>
      <Stack direction='row' sx={{p: 1, mt: 1 }}>
        <Box className='streamCell' width='10%'><RowBox>{hour(element.createdAt)}</RowBox></Box>
        <Box className='streamCell' width='25%'><RowBox>{element.type}</RowBox></Box>
        <Box className='streamCell' width='30%'><RowBox>{element.deviceCurrentName || '-'}</RowBox></Box>
        <Box className='streamCell' width='30%'><RowBox>{element.mainValue || '-'}</RowBox></Box>
        <Box width='5%' sx={{ display: 'flex', justifyContent: 'right' }}>
          <IconButton size='small' color='secondary' onClick={handleClick}>
            <Tooltip title='Details' arrow placement='top'><ListAltIcon /></Tooltip>
          </IconButton>
        </Box>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={open}
        sx={{ transform: 'translate(-16px, 0px)', fontSize: '12px' }}
        MenuListProps={{ disablePadding: true }}
        slotProps={menuStyle}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
      >
        <Box className='arrow' />
        <Box p={1.5} minWidth='500px'>
          <Box display='flex' mb={1}>
            <Box width='40%' fontWeight={500}>Field</Box>
            <Box width='60%' fontWeight={500}>Value</Box>
          </Box>
          <Divider sx={{ mb: 1.5 }} />
          {
            Object.keys(element.metadata).map((el, index) => {
              
              return (
                <Box key={index}>
                  {
                      typeof element.metadata[el] !== 'string'
                        ? typeof element.metadata[el] !== 'object' || element.metadata[el] === null
                          ? <Box display='flex' mb={1}><Box width='40%'>{el}</Box><Box width='60%'>{JSON.stringify(element.metadata[el])}</Box></Box>
                          : <>
                              <Box display='flex' mb={1}>
                                <Box width='40%'>{el}</Box><Box width='60%'>-</Box>
                              </Box>
                                {
                                  Object.keys(element.metadata[el]).map((x, inx) => 
                                    <Box key={inx} display='flex' mb={1}>
                                      <Box width='3%'></Box>
                                      <Box width='37%'>{x}</Box>
                                      {
                                        typeof element.metadata[el][x] === 'object'
                                          ? <Box width='60%'>{JSON.stringify(element.metadata[el][x])}</Box>
                                          : <Box width='60%'>{element.metadata[el][x]}</Box>
                                      }
                                    </Box>
                                  )
                                }
                            </>
                        : <Box display='flex' mb={1}><Box width='40%'>{el}</Box><Box width='60%'>{element.metadata[el]}</Box></Box>
                    }
                { index < Object.keys(element.metadata).length - 1 ? <Divider sx={{ mb: 1.5 }} /> : null }
                </Box>
              )
            })
          }
        </Box>
      </Menu>
    </Paper>
  )
}

export default LogRow